@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');

  .st0{fill:#F50F10;}
	.st1{fill:none;stroke:#FFFFFF;stroke-width:1.64;stroke-miterlimit:10;}
	.st2{fill:none;stroke:#FFFFFF;stroke-width:1.64;stroke-miterlimit:10;stroke-dasharray:0,0,7.26,7.26;}
	.st3{fill:none;stroke:#FFFFFF;stroke-width:1.64;stroke-miterlimit:10;stroke-dasharray:0,0,6.78,6.78;}
	.st4{fill:none;stroke:#FFFFFF;stroke-width:1.64;stroke-miterlimit:10;stroke-dasharray:0,0,6.9,6.9;}
	.st5{fill:none;stroke:#FFFFFF;stroke-width:1.64;stroke-miterlimit:10;stroke-dasharray:0,0,6.75,6.75;}
	.st6{fill:none;stroke:#FFFFFF;stroke-width:1.6;stroke-linecap:round;stroke-miterlimit:10;}
	.st7{opacity:0.15;fill:#FFFFFF;enable-background:new    ;}
	.st8{opacity:0;fill:#FFFFFF;enable-background:new    ;}
	.st9{fill:none;stroke:#FFFFFF;stroke-width:1.6;stroke-miterlimit:10;}
	.st10{fill:none;stroke:#FFFFFF;stroke-width:1.6;stroke-miterlimit:10;stroke-dasharray:0,0,7.43,7.43;}
	.st11{fill:none;stroke:#FFFFFF;stroke-width:1.6;stroke-miterlimit:10;stroke-dasharray:0,0,6.4,6.4;}
	.st12{fill:none;stroke:#FFFFFF;stroke-width:1.6;stroke-miterlimit:10;stroke-dasharray:0,0,6.89,6.89;}
	.st13{fill:none;stroke:#FFFFFF;stroke-width:1.65;stroke-linecap:round;stroke-miterlimit:10;}
	.st14{fill:none;stroke:#FFFFFF;stroke-width:1.54;stroke-miterlimit:10;}
	.st15{fill:none;stroke:#FFFFFF;stroke-width:1.54;stroke-miterlimit:10;stroke-dasharray:0,0,6.21,6.21;}
	.st16{fill:none;stroke:#FFFFFF;stroke-width:1.54;stroke-miterlimit:10;stroke-dasharray:0,0,6.2,6.2;}
	.st17{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;}
	.st18{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.22,6.22;}
	.st19{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.67,6.67;}
	.st20{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.04,6.04;}
	.st21{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.65,6.65;}
	.st22{fill:none;stroke:#FFFFFF;stroke-width:1.6;stroke-miterlimit:10;stroke-dasharray:0,0,7.96,7.96;}
	.st23{fill:none;stroke:#FFFFFF;stroke-width:1.6;stroke-miterlimit:10;stroke-dasharray:0,0,6.17,6.17;}
	.st24{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.57,6.57;}
	.st25{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.39,6.39;}
	.st26{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.4,6.4;}
	.st27{fill:none;stroke:#FFFFFF;stroke-width:1.54;stroke-miterlimit:10;stroke-dasharray:0,0,6.47,6.47;}
	.st28{fill:none;stroke:#FFFFFF;stroke-width:1.54;stroke-miterlimit:10;stroke-dasharray:0,0,6.44,6.44;}
	.st29{fill:none;stroke:#FFFFFF;stroke-width:1.54;stroke-linecap:round;stroke-miterlimit:10;}
	.st30{fill:none;stroke:#FFFFFF;stroke-width:1.54;stroke-linejoin:bevel;}
	.st31{fill:none;stroke:#FFFFFF;stroke-width:1.54;stroke-linejoin:bevel;stroke-dasharray:0,0,6.26,6.26;}
	.st32{fill:none;stroke:#FFFFFF;stroke-width:1.54;stroke-linejoin:bevel;stroke-dasharray:0,0,6.24,6.24;}
	.st33{fill:none;stroke:#FFFFFF;stroke-width:1.6;stroke-miterlimit:10;stroke-dasharray:0,0,8.5,8.5;}
	.st34{fill:none;stroke:#FFFFFF;stroke-width:1.6;stroke-miterlimit:10;stroke-dasharray:0,0,6.58,6.58;}
	.st35{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.11,6.11;}
	.st36{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.63,6.63;}
	.st37{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,7.11,7.11;}
	.st38{fill:none;stroke:#FFFFFF;stroke-width:1.58;stroke-miterlimit:10;stroke-dasharray:0,0,6.73,6.73;}

  
body, html, #root{
  height: 100%;
  position: relative;
}
body{
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
#root.configurator-embedded{
  max-height: calc(100vh - 85px) !important;
}
.content{
  margin: 0;
  max-height: 100%;
  position: relative;
  height: 100%;
  padding-left: 250px;
}
.wrapper-configurator {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 0;
}
.wrapper-configurator svg{
  z-index: 2;
}
.scroll{
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 250px;
  z-index: 3;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
.scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: none;
	background-color:transparent;
}

.scroll::-webkit-scrollbar
{
	width: 3px;
	background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #000000;
}
.wrapper-configurator svg{
  aspect-ratio: 2400/1420;
  object-fit: contain;
  position: absolute;
  top:0;
  left:0;
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.wrapper-configurator img {
  /* position: absolute;
    top: 0;
    left: 0; */
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

svg *[id^="room"] .st0{
  opacity: 0;
  transition: opacity .2s ease-in-out;
}
svg *[id^="room"] .st0.active,
svg *[id^="room"]:hover .st0{
  opacity: .5;
  filter: blur(5px);
}
svg *[id^="room"] g[id^="active_area"]{
  transition: opacity .2s ease-in-out;
  opacity: 0;

}
g[id^=active_area] .st7 { opacity: 0; transition: opacity .2s ease-in-out;}
g[id^=active_area]:hover .st7 { opacity: .35; }
svg *[id^="room"]:hover g[id^="active_area"]{
  cursor: pointer;
  z-index: 10;
  position: relative;
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal .wrapper-modal-content .modal-title{
  text-transform: uppercase;
  font-weight: 700;
}
.modal .wrapper-modal-content .modal-title,
.modal .wrapper-modal-content .modal-content{
  text-align: center;
}
.modal .scroll-content{
  flex: 1;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal .scroll-content::-webkit-scrollbar-track
{
	-webkit-box-shadow: none;
	background-color:transparent;
}

.modal .scroll-content::-webkit-scrollbar
{
	width: 3px;
	background-color: transparent;
}

.modal .scroll-content::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #000000;
}
.modal .wrapper-modal-content{
  overflow: auto;
    min-height: 300px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin: auto;
}
.modal {
  position: relative;
    padding: 15px;
    max-width: 1000px;
    max-height: 85vh;
    width: 80%;
    display: flex;
    background-color: #F5F5F5;
}
.modal-header .close{
  position: absolute;
  cursor:pointer;
  top: 20px;
  right: 0px;
  transform: translateY(-50%) rotate(45deg);
  padding: 10px;
  z-index: 10;
}
.modal-header {
  border-bottom: 1px solid #dedede;
  position: relative;
}
.image-brand img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.image-brand{
  position: absolute;
    top: 0;
    right: 15px;
    height: 70px;
    width: 100px;
}
.modal-title {
  margin: 0;
  font-size: 1.25rem;
  padding: 1rem;
  text-align: left;
}
.modal-content {
    text-align: left;
    padding: 1rem;
    z-index: 100;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.modal-footer {
  padding: 1rem;
  border-top: 1px solid #dedede;
  text-align: right;
}


.wrapper-unit-blocks{
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  width: 250px;
  height: 100%;
}


.wrapper-unit-blocks .unit-block.disabled .label,
.wrapper-unit-blocks .unit-block.disabled .wrapper-cta-image
{
  opacity: .3;
  cursor: default !important;
}

.wrapper-unit-blocks .unit-block.disabled .wrapper-cta .btn {
  opacity: .3;
  cursor: default;
}
.wrapper-unit-blocks .unit-block .wrapper-cta .btn img{
  position: absolute;
  top:50%;
  right: 0;
  transform: translate(-30%, -50%);
  
}

.wrapper-unit-blocks .unit-block .wrapper-cta .btn.verifica{
  background-color: #fff;
  color: black;
  margin-bottom: 20px;
}
.wrapper-unit-blocks .unit-block .wrapper-cta .btn{
  max-width: 80%;
  cursor: pointer;
  text-transform: uppercase;
  background-color: black;
  border: 1px solid black;
  color: white;
  font-weight: 700;
  font-size: 13px;
  padding: 12px 45px 12px 20px;
  position: relative;
}
.wrapper-unit-blocks .unit-block .wrapper-cta-image img{
  max-width: 100%;
}
.wrapper-unit-blocks .unit-block.disabled .wrapper-cta-image img{
  display: none;
}
.wrapper-unit-blocks .unit-block .label{
  margin-bottom: 2px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
}
.wrapper-unit-blocks .unit-block .wrapper-blocks{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.wrapper-unit-blocks .unit-block{
  display: flex;
}
.wrapper-unit-blocks .unit-block:last-child{
  border-right: none;
}

.wrapper-unit-blocks .unit-block .populated{
  order: -1;
}
.wrapper-unit-blocks .unit-block .message{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-weight: 700;
font-size: 16px;

}
.wrapper-unit-blocks .inline-blocks{
  display: flex;
  padding: 20px;
}
.wrapper-unit-blocks .unit-block.single .label{
  padding: 0 5px;
}
.wrapper-unit-blocks .unit-block.single{
  flex-direction: column;
    text-align: center;
    flex: 1;
    align-content: center;


}
.wrapper-unit-blocks .unit-block:not(.single){
    flex-direction: column;
    width: 100%;
    min-width: 250px;
    justify-content: flex-start;
    display: flex;
    position: relative;
    text-align: center;
    align-items: center;text-align: center;
}

.wrapper-unit-blocks .unit-block .block{
  display: inline-block;
  min-height: 110px;
}
.wrapper-unit-blocks .unit-block .block .wrapper-cta-image{
  width: 76px;
  height: 76px;
  border: 2px dashed black;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  margin: 10px 10px 0 10px;
  cursor: pointer;
}
.wrapper-unit-blocks .unit-block .wrapper-blocks .block .wrapper-cta-image{
  cursor: default;
}

.modal .wrapper-element.selected{
  border: 4px solid red;
}
.modal .wrapper-elements.step-1 .wrapper-element table{
  width: 100%;
}
.modal .wrapper-elements.step-1 .wrapper-element{
  width: 46%;
}
.modal .wrapper-elements.step-3 .wrapper-element.disabled{
  opacity: .2;
  cursor: default !important;
}
.modal .wrapper-elements.step-3 .wrapper-element.not-valid{
  display: none;
}
.modal .wrapper-elements.step-3 .wrapper-element .wrapper-childs{
  display: none;
}
.modal .wrapper-elements.step-3 .wrapper-element.selected .cover{
  text-align: left;
}
.modal .wrapper-elements.step-3 .wrapper-element.selected .wrapper-childs{
  float: right;
  width: 70%;
}
@media screen and (max-width: 800px) {
  .modal .wrapper-elements.step-3 .wrapper-element.selected .wrapper-childs{
    float: right;
    width: 90%;
  }
  
}
.modal .wrapper-elements.step-3 .wrapper-element.selected .cover,
.modal .wrapper-elements.step-3 .wrapper-element.selected .wrapper-childs{
  display: table-cell;
  vertical-align: top;
}
.modal .wrapper-elements.step-3.is-child .wrapper-element:not(.selected){
  display: none;
}
.modal .wrapper-elements.step-3{
  position: relative;
  margin-bottom: 15px;
}

.modal .wrapper-elements.step-3 .wrapper-element.selected .cover{
  width: 265px;
  height: 100%;
  background-color: white;
}
.modal .wrapper-elements.step-3 .wrapper-element.selected{
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
  width: 100%;
  border: none;
  background-color: #F5F5F5;
  display: table;
}
.modal .wrapper-elements.step-3 table thead th{
  padding: 10px 10px 20px;
}
.modal .wrapper-elements.step-3 table thead th{
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}
.modal .wrapper-elements.step-3 table{
  position: relative;
}
.modal .wrapper-elements.step-3 table tbody tr.selected td:first-child:after{
  box-sizing: border-box;
  content:'';
  position:absolute;
  /* do not use top and bottom */
  left:0;
  transform: translate(0,-20px);
  right:0px;

  /* display: block; */
  height: 60px;
  box-shadow: 0px 0px 0px 4px red inset;
}
.modal .wrapper-elements.step-3 table tbody tr td .content-cell{
  background-color: #F5F5F5;
  padding: 10px 0;
}
.modal .wrapper-elements.step-3 table tbody tr td{
  
  padding: 10px 15px;
  margin-bottom: 10px;
}
.modal .wrapper-elements.step-3 table tbody tr{
  background-color: white;
  border-spacing: 0 10px;
}
.modal .wrapper-elements.step-3 table{
  width: 100%;
  border-spacing: 0 10px;
  text-align: center;
}
.modal .wrapper-elements.step-3 table td.name{
  width: 50%;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
}
.modal .wrapper-elements.step-3 table td.v-index,
.modal .wrapper-elements.step-3 table td.capacity{
  width: 25%;
}
.modal .wrapper-element.step-3.selected{
  border: none;
}
.modal .wrapper-element .wrapper-img img {
  max-width: 100%;
}
.modal .wrapper-element{
  border: 4px solid white;
  width: 260px;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  background-color: white;
  cursor: pointer;
}
.modal .wrapper-element .wrapper-img{
  text-align: center;
}
.modal .wrapper-element .wrapper-img img{
  max-width: 110px;
  margin: auto;
}
.modal .wrapper-element .wrapper-data .family{
  text-align: center;
}
.modal .wrapper-element .wrapper-data .name{
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 0 0 15px;
}
.modal .wrapper-element .wrapper-data table td:nth-child(2){
  text-align: center;
  font-weight: 700;
}
.modal .wrapper-element .wrapper-data table td{
  width: 50%;
  padding: 10px 15px;
  background-color: #EAEAEA;
  font-weight: 500;
  font-size: 12px;
}
.modal .wrapper-title{
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  padding: 10px;
}
.modal .wrapper-cta {
  padding:10px;
  min-height: 60px;
  box-sizing: border-box;
}
.modal .wrapper-cta .modal-button.back {
  border: 1px solid black;
  background-color: white;
  color: black;
}
.btn-help{
  width: 50px;
  height: 50px;
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: #ccc;
  font-weight: 700;
  font-size: 26px;
  color: black;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  z-index: 100;
}

.modal .wrapper-cta .modal-button.left,
.modal .wrapper-cta .modal-button.back {
  float: left;
}

.modal .modal-cta {
  width: 50%;
}
.modal .wrapper-cta .modal-button.right {
  float: right;
}
.modal .wrapper-cta .modal-button {
  text-transform: uppercase;
  background-color: black;
  border: 1px solid black;
  color: white;
  font-weight: 700;
  font-size: 13px;
  padding: 12px;
  position: relative;
  display: inline-block; 
  cursor: pointer;
}
.modal .wrapper-cta{
  text-align: right;
}
.wrapper-products-overlay{
  position: absolute;
  height: 100%;
  width: 100%;
  top:0;
  right:0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top left;
}

.wrapper-preview.wrapper-elements.step-3 table td.name,
.modal .wrapper-preview.wrapper-elements.step-3 table td.name{
  width: 25%;
}
.wrapper-preview.wrapper-elements.step-3 table td.unit,
.modal .wrapper-preview.wrapper-elements.step-3 table td.unit{
  text-align: right;
  width: 25%;
  background-color: #F5F5F5;
}
.wrapper-preview.wrapper-elements.step-3 table,
.modal .wrapper-preview.wrapper-elements.step-3 table{
  width: 90%;
  float: right;
}
.wrapper-preview.wrapper-elements.step-3 table tr.child.interne:nth-child(1) .unit,
.modal .wrapper-preview.wrapper-elements.step-3 table tr.child.interne:nth-child(1) .unit{
  font-size: 22px;
}
.wrapper-preview.wrapper-elements.step-3 table tr.child .unit,
.modal .wrapper-preview.wrapper-elements.step-3 table tr.child .unit{
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  
}
.wrapper-preview.wrapper-elements.step-3 table tr.child.interne .unit,
.modal .wrapper-preview.wrapper-elements.step-3 table tr.child.interne .unit{
  font-size: 0px;
}
.wrapper-preview.wrapper-elements.step-3 table tr.child.totals td,
.modal .wrapper-preview.wrapper-elements.step-3 table tr.child.totals td{
  background-color: #F5F5F5;
  border-top:1px solid black;
  border-bottom:1px solid black;
}

.wrapper-block .label { font-weight: 400!important; font-size: 12px!important; }
.wrapper-block .btn-delete{display: none;}
.wrapper-block{
  position: relative;
}

.wrapper-block:hover .btn-delete{
  display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    border-radius: 50%;
    right: 0;
    background: #fff;
    transform: translate(20%,-37%);
    cursor:pointer;
}
.wrapper-pdf{
  /* display: none; */
  width: calc((2480px / 3.46)*1);
  height: calc((3508px / 3.46) *1);
  padding: 30px;
}
.wrapper-pdf.page-1{
  /* display: none; */
  width: calc((2480px / 3.46)*1.047);
  height: calc((3508px / 3.46) *1.047);
  position: relative;

  padding: 30px;
}
.wrapper-pdf.page-1::after{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  background: #fff; 
  z-index: 1;

}
.wrapper-pdf.page-2.qty-4{
  background: url('assets/pdf/PAG2__4.jpg') no-repeat top left transparent; 
  background-size: contain;
}
.wrapper-pdf.page-2.qty-3{
  background: url('assets/pdf/PAG2__3.jpg') no-repeat top left transparent; 
  background-size: contain;
}
.wrapper-pdf.page-2.qty-2{
  background: url('assets/pdf/PAG2__2.jpg') no-repeat top left transparent; 
  background-size: contain;
}
.wrapper-pdf.page-2.qty-1{
  background: url('assets/pdf/PAG2__1.jpg') no-repeat top left transparent; 
  background-size: contain;
}
.wrapper-pdf.page-2{
  
  position: relative;
}
.wrapper-pdf.page-2 .content .product.interno .wrapper-name{
  max-width: 100%;
  text-align: center;
  font-weight: bold;
  transform: translateY(-15px);
  background: white;
  display: inline-block;
  padding: 0 10px;
}
.wrapper-pdf.page-2 .content .product.idroniche .wrapper-name,
.wrapper-pdf.page-2 .content .product.esterno .wrapper-name{
  max-width: 100%;
  text-align: center;
  font-weight: bold;
  transform: translateY(-30px);
  background: white;
  display: inline-block;
  padding: 0 10px;
}
.wrapper-pdf.page-2 .content .product.esterno .wrapper-name{
  transform: translateY(-38px);

}
.wrapper-pdf.page-2 .content .product.interno img{
  max-width: 70%;
}
.wrapper-pdf.page-2 .content .product.interno.interno-3{
  left: 583px;
}
.wrapper-pdf.page-2 .content .product.interno.interno-2{
  left: 397px;
}
.wrapper-pdf.page-2 .content .product.interno.interno-1{
  left: 214px;
}
.wrapper-pdf.page-2 .content .product.interno{
  width: 150px;
  position: absolute;
  top: 230px;
  left: 30px;
  text-align: center;
}
.wrapper-pdf.page-2 .content .product.idroniche img,
.wrapper-pdf.page-2 .content .product.esterno img{
  max-width: 70%;
}
.wrapper-pdf.page-2 .content .product.esterno{
  width: 270px;
  position: absolute;
  top: 550px;
  left: 42px;
  text-align: center;
  font-weight: bold;
}
.wrapper-pdf.page-2 .content .product.idroniche{
  width: 270px;
  position: absolute;
  top: 620px;
  left: 455px;
  text-align: center;
  font-weight: bold;
}
.wrapper-pdf.page-2 .content{
  position: absolute;
  padding: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top:0;
  /* background: url('assets/pdf/Ecodan-pag2-grid.png') no-repeat top left transparent; 
  background-size: contain; */

}
.wrapper-pdf table,.wrapper-pdf  th,.wrapper-pdf  td {
  border: 1px solid;
  border-collapse: collapse;
  text-align: center;
}
.change-lang{
  position: absolute;
  top: 90px;
  right: 30px;
  z-index: 100;
}